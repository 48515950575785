import PromoAPI from '@services/promo-query.service';
import { useQuery, usePaginatedQuery, useInfiniteQuery, QueryResult } from 'react-query';
import { IPageParams, IAPIResponse, IPagination, IPromo } from '@models';
import { basicHeaders } from '@utils/';
import { IMap } from '@models/map.model';


// -- react query
const getPromo = async (key: string, slug: string) => {
    const { data } = await PromoAPI.get(`/${slug}`);
    return data;
};


export const getPromoPage = async (key: string, page:number ) => {
    const pageParams: IPageParams = {
        pageNumber: page,
        pageSize: 20
    };

    //const data: IAPIResponse<IPagination<IPromo>> = await PromoAPI.get(`recent?page=${pageParams.pageNumber}&size=${pageParams.pageSize}`);
    const { data }:IAPIResponse<IPagination<IPromo>> = await PromoAPI.get(`?page=${pageParams.pageNumber}&size=${pageParams.pageSize}`);

    console.log('get business page ' + page, data.data);
    return data.data;
};


export const useGetBusinessPage = (pageNumber: number = 0) => {
    return usePaginatedQuery(['businessPage', pageNumber], getPromoPage, {});
}

export const useGetBusinessInfinite = (pageNumber: number) => {
    // console.log('useGetBusienssInfinite -- page ', pageNumber);
    return useInfiniteQuery(['businessInfinite', pageNumber], getPromoPage, {
        getFetchMore: (lastGroup, allGroups) => {console.log('getFetchMore ' + pageNumber, lastGroup); return lastGroup.last?undefined:lastGroup.number+1;}
    });
}
