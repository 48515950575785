import React from "react";

import tw from 'twin.macro';
import { SectionContainer, Row, Col } from '@components/common/Layout';
import { SectionLink } from 'react-scroll-section';
import SectionTitle from '@components/common/SectionTitle';
import Button from '@components/common/Button';
import Particle from '@components/common/Particle';
import VintageBox from '@components/common/VintageBox';
import TextRich from "@components/TextRich";
import markdownRenderer from '@components/MarkdownRenderer';

import { FaDesktop } from "react-icons/fa";

import AppScreenImage from '@assets/images/app/promo/heading.svg';

import BannerSectionWrapper from './claim.style';
import web from '@assets/data/web';


const Place = tw.h1`font-bold text-highlight font-serif text-4xl lg:text-6xl`;
const Header = tw.h1`font-extrabold text-primarydark text-3xl lg:text-4xl`;
const Text = tw.div`pt-3 text-xl w-3/4 md:w-full`;
const BannerContainer= tw.div`sm:w-full max-w-full sm:mx-auto pt-20 pb-4 sm:pb-0 sm:pt-0 md:pt-10 bg-custompink h-full`;
const ColMobile = tw(Col)`hidden sm:block`;


const PromoSection = () => {

  return (
    <BannerContainer>
    <BannerSectionWrapper>
      <SectionContainer id="promo" fullWidthSM>
        <Row Vcenter={true}>
          <Col sm={12} xs={12} md={7}>
            <Place>Promociones</Place>            
          </Col>

          <ColMobile sm={12} xs={12} md={5} pr={0}>
            <div className="app-screen-image-wrapper">
              <img src={AppScreenImage} alt={web.name} />
            </div>
          </ColMobile>
        </Row>
        <Row Hcenter>

        </Row>
      </SectionContainer>
    </BannerSectionWrapper>
    </BannerContainer>
  );
};

export default PromoSection;
