import React, { useRef, useState } from 'react';
import tw from 'twin.macro';

import Layout, {Container, ContentWithPaddingXl} from '@components/Layout';
import { FaChevronDown } from 'react-icons/fa';
import Header from '@components/Header';
import SEO from '@components/Seo';
import CookieBanner from '@components/Cookies/CookieBanner';
import { Link } from 'gatsby';
import PostPreview from '@components/Post/PostPreview';
import ArticlePreview from '@components/Post/ArticlePreview';
import Pager from '@components/Pager';
import MiniFooter from '@components/Footer/MiniFooter';
import LayoutContent from '@components/LayoutContent';
import { CircularProgress } from '@material-ui/core';
import { IPromo, IAPIResponse, IPagination } from '@models';
import { useInfiniteQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import PromoItemCardComponent from '@components/promo/PromoItemCardComponent';


import { getPromoPage } from '@services/promo.service';
import PromoSection from '@components/promo/PromoSection';


const SectionContainer = tw.section`p-4 pt-0 block flex justify-center flex-wrap flex-row items-stretch w-full`;
const PromosContainer= tw.div`md:pt-20 sm:pt-0 md:pt-10 z-0 justify-center grid grid-cols-1 sm:grid-cols-2 gap-4`;
const MoreIcon = tw(FaChevronDown)`w-12 h-12 text-primary-900`;

const PromoList : React.FC<any> = props => { // ({location, pageContext})

    
const [pageNumber, setPageNumber] = useState(0);

const [promoList, setPromoList] = useState<Array<IPromo>>(new Array());
const [pagination, setPagination] = useState<IPagination>(null);
const debug = false;

const onNextPage = () => {
    console.log('onNextPage');
    setPageNumber(pageNumber + 1);
    // businessPage();
}

const onPrevPage = () => {
    console.log('onPrevPage');
    setPageNumber(pageNumber - 1);
    
    // businessPage();
}

const {
    status,
    data,
    error,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
} = useInfiniteQuery(['promoInfinite', pageNumber], getPromoPage, {
    getFetchMore: (lastGroup, allGroups) => {console.log('getFetchMore promo list ' + pageNumber, allGroups); return lastGroup.last?undefined:1;}
});

const loadMoreRef = useRef();


const addPage = (pageInfo: IAPIResponse<IPagination<IPromo>>) => {
    console.log('addPage --- page -- ', pageInfo.data.number);


    setPromoList(promoList.concat(pageInfo?.data.content));
    setPagination(pageInfo?.data);
}

return (
    <LayoutContent>
        <Header expand={true}/>
        <SEO
            lang="es" title="Cocina para ti" keywords={[`solidaridad`, `barrio`, `madrid`, `restaurantes`, `para llevar`, `banco de alimentos`]} 
        />       
        <PromoSection/>
        <SectionContainer id="promo-list">
        
        {status === 'loading' ? (
            <CircularProgress color="primary" size="5rem" thickness={2}/>
        ) : status === 'error' ? (
            <span>Ups</span>
        ) : (
        <>
            <PromosContainer>
            {data?.map((page, i) => (
            <React.Fragment key={i}>
            {
            
            page.content?.map((promo: IPromo, index: number) => (
                <PromoItemCardComponent promo={promo} key={promo.token}></PromoItemCardComponent>
            ))}
            </React.Fragment>
            ))}
            <div>
            <button
                className="text-secondary font-weight-bold"
                ref={loadMoreRef}
                onClick={() => {setPageNumber(pageNumber+1); fetchMore(pageNumber);}}
                disabled={!canFetchMore || isFetchingMore}
            >
                {isFetchingMore
                ? 'Cargando...'
                : canFetchMore
                ? <MoreIcon/>
                : ''}
            </button>
            {
            // pagina {pageNumber}
            }
            </div>
            </PromosContainer>
        </>
        )}
        </SectionContainer>    
        

        <MiniFooter />
        <CookieBanner/>
        {debug && 
            <ReactQueryDevtools initialIsOpen />
        }
    </LayoutContent>
);
}

export default PromoList;
