
const web = {
    name: "Cocina para ti",
    claim: "Tu barrio cocina para ti",       
    bannerText: "Conecta tu bar o restaurante con tus vecinos ",
    bannerImage: {
        image: {
          src: "images/app/comercio-ilustracion-crop.svg"
        }
    }      
}

export default web;